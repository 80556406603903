import React from 'react';
import { Headings } from '../../../components';
import { BsDiscord, BsYoutube } from 'react-icons/bs'
import './Community.css';


const Community = () => {
  return (
    <div className="community">
      <br /><br /><br /><br />
      <Headings title="Community" text="Lär dig mer om AI i vårt forum eller på vår YouTube-kanal" />

      <div className="content-container" style={{ display: 'flex' }}>
        <div className='text'>
          <p >
            Lär dig mer om AI, få hjälp och diskutera med andra i vårt forum eller ta del av gratis content på YouTube.
          </p>
          <p> Du hittar vårt forum i chatappen Discord. Som du kan ladda ner eller köra direkt i webläsaren.
          </p>
        </div>
        <div className="community-links-container" style={{ flex: 1 }}>
          <div className="community-link">
            <a href="https://discord.gg/GaGQeE23vd" className="icon-link" target="_blank" rel="noopener noreferrer">
              <BsDiscord className='discord-icon' color='#7289DA' size={70} />
            </a>
            <br />
            Techlära på Discord
          </div>
          <div className="community-link">
            <a href="https://www.youtube.com/channel/UCXZthb2sGbrkS6aEX8EcEsg" className="icon-link" target="_blank" rel="noopener noreferrer">
              <BsYoutube className='youtube-icon' color='#FF0000' size={70} />
            </a>
            <br />
            Techlära på YouTube
          </div>
        </div>
      </div>
      <br /><br /><br /><br />
    </div>
  );
};

export default Community;
